import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import Swal from "sweetalert2";
import { plans } from "@/constants/enum";
import { fetchLists, fetchPeriods, fetchPlans } from "@/utils/refetch";

export const usePlanStore = defineStore("plan", {
  state: () => {
    return {
      subscriptionTypes: [],
      Periods: [],
      Plans: [],
      subscriptionPlans: [],

      GymPlans: [],
      MassagePlans: [],
      TrainerPlans: [],

      GymList: [],
      MassageList: [],
      TrainerList: [],

      GymPeriods: [],
      MassagePeriods: [],
      TrainerPeriods: [],

      GymPlanDetail: [],
      MassagePlanDetail: [],
      TrainerPlanDetail: [],
    };
  },
  actions: {
    // async deletePlan(id, type) {
    //   try {
    //     const response = await api.delete(
    //       `${apiUrls.prolife}/plans/${type}/${id}`
    //     );
    //     fetchPlans(type);
    //     Swal.fire("Plan Deleted Successfully", "", "success");
    //     // await this.fetchSubscriptionPlans();
    //   } catch (error) {}
    // },
    async addPlans(plan = { serviceTypeId, periodId, price }, type) {
      try {
        const response = await api.post(
          `${apiUrls.prolife}/plans/${type}`,
          plan
        );
        fetchPlans(type);
        Swal.fire("Plan Added Successfully", "", "success");
      } catch (error) {}
    },
    async updatePlans(
      gymPlanPeriodId,
      plan = { serviceTypeId, periodId, price },
      type
    ) {
      try {
        const response = await api.patch(
          `${apiUrls.prolife}/plans/${type}/${gymPlanPeriodId}`,
          plan
        );
        fetchPlans(type);
        Swal.fire("Plan Updated Successfully", "", "success");
      } catch (error) {}
    },

    async addServiceType(plan = { name, req_type }) {
      try {
        const response = await api.post(
          `${apiUrls.prolife}/subscription-types`,
          plan
        );
        // fetchLists(type)
        Swal.fire("Subscription Plan Added Successfully", "", "success");
      } catch (error) {}
    },

    async deleteServiceType(id, type) {
      try {
        const response = await api.delete(`${apiUrls.prolife}/${type}/${id}`);
        fetchLists(type);
      } catch (error) {}
    },

    async updateServiceType(serviceTypeId, service = {}) {
      try {
        const response = await api.patch(
          `${apiUrls.prolife}/${service.req_type}/${serviceTypeId}`,
          service
        );
        fetchLists(service.req_type);
        Swal.fire("Plan Updated Successfully", "", "success");
      } catch (error) {}
    },
    // Lists

    async fetchGymList({ type }) {
      try {
        const response = await api.get(apiUrls.prolife + `/lists?type=${type}`);
        this.GymList = response.data.data;
      } catch (error) {}
    },
    async fetchMassageList({ type }) {
      try {
        const response = await api.get(apiUrls.prolife + `/lists?type=${type}`);
        this.MassageList = response.data.data;
      } catch (error) {}
    },
    async fetchTrainerList({ type }) {
      try {
        const response = await api.get(apiUrls.prolife + `/lists?type=${type}`);
        this.TrainerList = response.data.data;
      } catch (error) {}
    },

    // Plans
    async fetchGymPlans({ type }) {
      try {
        const response = await api.get(`${apiUrls.prolife}/get/plans?type=${type}`);
        this.GymPlans = response.data.data;
        return this.GymPlans;
      } catch (error) {}
    },
    async fetchMassagePlans({ type }) {
      try {
        const response = await api.get(`${apiUrls.prolife}/subscription-types`);
        this.MassagePlans = response.data.data;
      } catch (error) {}
    },
    async fetchTrainerPlans({ type }) {
      try {
        const response = await api.get(`${apiUrls.prolife}/plans?type=${type}`);
        this.TrainerPlans = response.data.data;
      } catch (error) {}
    },

    // Periods
    async fetchGymPeriods({ type }) {
      try {
        const response = await api.get(
          `${apiUrls.prolife}/periods?type=${type}`
        );
        this.GymPeriods = response.data.data;
      } catch (error) {}
    },
    async fetchMassagePeriods({ type }) {
      try {
        const response = await api.get(
          `${apiUrls.prolife}/periods?type=${type}`
        );
        this.MassagePeriods = response.data.data;
      } catch (error) {}
    },
    async fetchTrainerPeriods({ type }) {
      try {
        const response = await api.get(
          `${apiUrls.prolife}/periods?type=${type}`
        );
        this.TrainerPeriods = response.data.data;
      } catch (error) {}
    },

    // async addPeriods(period = {name: null, duration: null, session_count: null, session: null}, type) {
    async addPeriods(period = {}, type) {
      try {
        const response = await api.post(
          `${apiUrls.prolife}/periods/${type}`,
          period
        );
        fetchPeriods(type);
        Swal.fire("Period Added Successfully", "", "success");
      } catch (error) {
        console.log(error);
      }
    },

    // async deletePeriod(id, type) {
    //   try {
    //     const response = await api.delete(
    //       `${apiUrls.prolife}/periods/${type}/${id}`
    //     );

    //     fetchPeriods(type);
    //     Swal.fire(
    //       type.charAt(0).toUpperCase() +
    //         string.slice(1) +
    //         " Period Deleted Successfully",
    //       "",
    //       "success"
    //     );
    //   } catch (error) {}
    // },

    async updatePeriod(PeriodId, plan = {}, type) {
      try {
        const response = await api.patch(
          `${apiUrls.prolife}/periods/${type}/${PeriodId}`,
          plan
        );
        fetchPeriods(type);
        Swal.fire("Plan Updated Successfully", "", "success");
      } catch (error) {}
    },

    async viewMore(id, type) {
      // alert(type)
      try {
        if (type === plans.Gym) {
          // Assuming GymPlans is an array of objects and each object has an 'id' property
          let result = this.GymPlans.find((plan) => plan.gym_plan_id == id);
          if (result) {
            // this.GymPlanDetail= []
            this.GymPlanDetail = result;
            return true;
          } else {
            await this.fetchGymPlans({ type: plans.Gym });
            result = this.GymPlans.find((plan) => plan.gym_plan_id == id);
            if (result) {
              this.GymPlanDetail = result;
              return true; // Return the found plan after fetching
            } else {
              console.error("Gym Plan not found after fetching the list");
              return null; // Return null if still not found
            }
          }
        } else if (type === plans.Massage) {
          let result = this.MassagePlans.find(
            (plan) => plan.massage_type_id == id
          );
          if (result) {
            this.MassagePlanDetail = result;
            return true;
          } else {
            await this.fetchMassagePlans({ type: plans.Massage });
            result = this.MassagePlans.find(
              (plan) => plan.massage_type_id == id
            );
            if (result) {
              this.MassagePlanDetail = result;
              console.log(this.MassagePlanDetail);
              return true; // Return the found plan after fetching
            } else {
              console.error("Massage Plan not found after fetching the list");
              return null; // Return null if still not found
            }
          }
        } else if (type === plans.Trainer) {
          let result = this.TrainerPlans.find(
            (plan) => plan.personal_training_id == id
          );
          if (result) {
            this.TrainerPlanDetail = result;
            console.log(this.TrainerPlanDetail);
            return true;
          } else {
            await this.fetchTrainerPlans({ type: plans.Trainer });
            result = this.TrainerPlans.find(
              (plan) => plan.personal_training_id == id
            );
            if (result) {
              this.MassagePlanDetail = result;
              console.log(this.MassagePlanDetail);
              return true; // Return the found plan after fetching
            } else {
              console.error("Trainer Plan not found after fetching the list");
              return null; // Return null if still not found
            }
          }
        }
      } catch (error) {
        console.error("Error fetching Gym Plan details:", error);
        throw error; // Rethrow the error to handle it upstream if necessary
      }
    },











    async fetchSubTypes() {
      try {
        const response = await api.get(`${apiUrls.prolife}/subscription-types`);
        this.subscriptionTypes = response.data.data;
        this.subscriptionPlans = response.data.data;
        return this.subscriptionTypes;
      } catch (error) {}
    },

    async addSubTypes(payload) {
      try {
        const response = await api.post(
          `${apiUrls.prolife}/subscription-types`,
          payload
        );
        this.fetchSubTypes();
        Swal.fire("Subscription Type Added Successfully", "", "success");
      } catch (error) {}
    },

    async updateSubTypes(subTypeId, payload) {
      try {
        const response = await api.patch(
          `${apiUrls.prolife}/subscription-types/update/${subTypeId}`,
          payload
        );
        this.fetchSubTypes();
        Swal.fire("Subscription Type Updated Successfully", "", "success");
      } catch (error) {}
    },

    async deleteSubType(id) {
      try {
        const response = await api.delete(
          `${apiUrls.prolife}/subscription-types/delete/${id}`
        );
        this.fetchSubTypes();
        Swal.fire("Period Deleted Successfully", "", "success");
      } catch (error) {}
    },

    async fetchPeriods() {
      try {
        const response = await api.get(`${apiUrls.prolife}/periods`);
        this.Periods = response.data.data;
      } catch (error) {}
    },
    async addPeriod(period) {
      try {
        const response = await api.post(
          `${apiUrls.prolife}/periods`,
          period
        );
        this.fetchPeriods();
        Swal.fire("Period Added Successfully", "", "success");
      } catch (error) {
        console.log(error);
      }
    },
    async deletePeriod(id) {
      try {
        const response = await api.delete(
          `${apiUrls.prolife}/periods/delete/${id}`
        );

        this.fetchPeriods();
        Swal.fire("Period Deleted Successfully", "", "success");
      } catch (error) {}
    },
    async updatePeriod(PeriodId, plan) {
      try {
        const response = await api.patch(
          `${apiUrls.prolife}/periods/update/${PeriodId}`,
          plan
        );
        this.fetchPeriods();
        Swal.fire("Plan Updated Successfully", "", "success");
      } catch (error) {}
    },


    async fetchPlans() {
      try {
        const response = await api.get(`${apiUrls.prolife}/plans`);
        this.Plans = response.data.data;
      } catch (error) {}
    },
    async addPlan(plan) {
      try {
        const response = await api.post(
          `${apiUrls.prolife}/plans`,
          plan
        );
        this.fetchPlans();
        Swal.fire("Plan Added Successfully", "", "success");
      } catch (error) {
        console.log(error);
      }
    },
    async deletePlan(id) {
      try {
        const response = await api.delete(
          `${apiUrls.prolife}/plans/delete/${id}`
        );

        this.fetchPlans();
        Swal.fire("Plan Deleted Successfully", "", "success");
      } catch (error) {}
    },
    async updatePlan(id, plan) {
      try {
        const response = await api.patch(
          `${apiUrls.prolife}/plans/update/${id}`,
          plan
        );
        this.fetchPlans();
        Swal.fire("Plan Updated Successfully", "", "success");
      } catch (error) {}
    },

    async updateSubPlan(id, subPlan) {
      try {
        const response = await api.patch(
          `${apiUrls.prolife}/sub-plans/update/${id}`,
          subPlan
        );
        this.fetchSubTypes();
        Swal.fire("Plan Updated Successfully", "", "success");
      } catch (error) {}
    },

    async addSubPlan(subPlan) {
      try {
        const response = await api.post(
          `${apiUrls.prolife}/sub-plans`,
          subPlan
        );
        this.fetchSubTypes();
        Swal.fire("Plan Added Successfully", "", "success");
      } catch (error) {
        console.log(error);
      }
    },

    async deleteSubPlan(id) {
      try {
        const response = await api.delete(
          `${apiUrls.prolife}/sub-plans/delete/${id}`
        );

        this.fetchSubTypes();
        Swal.fire("Period Deleted Successfully", "", "success");
      } catch (error) {}
    },
  },
});
