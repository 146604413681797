<template>
  <!-- Add Plan -->
  <div class="modal fade" id="sub-type-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Gym</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Plan Name</label>
                      <input
                        type="text"
                        name="name"
                        required
                        v-model="subscriptionType.name"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { usePlanStore } from "@/store/planStore";
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { plans } from "@/constants/enum";
// stores
const planStore = usePlanStore();

const props = defineProps({
  subTypeId: null,
});
// data
const subscriptionType = reactive({
  name: "",
  req_type: plans.Gym,
});

async function submitForm() {
  if (props.subTypeId) {
    // update
    await planStore.updateSubTypes(props.subTypeId, subscriptionType);
  } else {
    // add
    await planStore.addSubTypes(subscriptionType);
    // await planStore.fetchGymList({type:plans.Gym})
  }
  $("#sub-type-modal").modal("hide");
}

watch(
  () => subscriptionType,
  () => {
    // setTimeout is used here so the service will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.subTypeId,
  (newSubTypeId) => {
    if (props.subTypeId) {
      const existingSubscriptionType = planStore.subscriptionTypes.find((plan) => plan.id == newSubTypeId);
      console.log(existingSubscriptionType)
      if (existingSubscriptionType) {
        subscriptionType.name = existingSubscriptionType.subscriptionType;
      } else {
        subscriptionType.name = ""; // Clear if no match is found
      }
    } else {
      subscriptionType.name = "";
    }
  },
  { immediate: true }
);

onMounted(() => {});
</script>
