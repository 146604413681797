<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Gym Plan List</h4>
            <h6>Manage Your Gym Plans</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            v-if="canAddNewPlan"
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('new')"
          >
            <vue-feather type="plus-circle" class="me-2"></vue-feather>Add New Plan
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="card-title">Secondary</div>
            </div>
            <div class="card-body">
              <div class="accordion accordion-secondary" id="accordionSecondaryExample">
                <div
                  class="accordion-item"
                  v-for="plan in planStore.GymPlans"
                  :key="plan.gym_plan_id"
                >
                  <h2 class="accordion-header" :id="'headingPrimary' + plan.gym_plan_id">
                    <button
                      style="height: 80px"
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapsePrimary' + plan.gym_plan_id"
                      aria-expanded="false"
                      :aria-controls="'collapsePrimary' + plan.gym_plan_id"
                      @click="viewMore(plan.gym_plan_id)"
                    >
                      {{ plan.gym_plan_name }}
                    </button>
                  </h2>

                  <div class="row">
                    <div class="col-md-12">
                      <div
                        :id="'collapsePrimary' + plan.gym_plan_id"
                        class="accordion-collapse collapse"
                        :aria-labelledby="'headingPrimary' + plan.gym_plan_id"
                        data-bs-parent="#accordionPrimaryExample"
                      >
                        <!-- USER TABLE -->
                        <filter-table
                          :extra-class="['table', 'datanew']"
                          :columns="GymPlanDetail.columns"
                          :data="GymPlanDetail.data"
                          :filters="GymPlanDetail.filters"
                        >
                          <template #header>
                            <h4>{{ plan.gym_plan_name }}</h4>
                          </template>
                          <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'price'">
                              {{ formatAmount(record.price) }}
                            </template>

                            <template v-else-if="column.key === 'action'">
                              <td class="action-table-data">
                                <div class="edit-delete-action">
                                  <a
                                    @click="
                                      openModal('update', record.gym_plan_period_id)
                                    "
                                    class="me-2 p-2 mb-0"
                                  >
                                    <i data-feather="edit" class="feather-edit"></i>
                                  </a>
                                  <a
                                    @click="deletePlan(record.gym_plan_period_id)"
                                    class="me-2 confirm-text p-2 mb-0"
                                    href="javascript:void(0);"
                                  >
                                    <i data-feather="trash-2" class="feather-trash-2"></i>
                                  </a>
                                </div>
                              </td>
                            </template>
                          </template>
                        </filter-table>
                        <!-- USER TABLE -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PlanModal
    :gym_plan_period_id="selectedGym_plan_period_id"
    :gym_plan_id="planStore.GymPlanDetail.gym_plan_id"
  />
</template>

<script setup>
import PlanModal from "./modal/gym-detail-modal.vue";
import { computed, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { usePlanStore } from "@/store/planStore";
import { formatAmount } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import { plans } from "@/constants/enum";
// store
const planStore = usePlanStore();

// data
/**
 * Id of Plan that is being edited
 */
const selectedGym_plan_period_id = ref(null);
const GymPlanDetail = {
  filters: ["name"],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.gym_plan_name.toLowerCase();
          b = b.gym_plan_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      sorter: {
        compare: (a, b) => {
          a = a.price.toLowerCase();
          b = b.price.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
      sorter: {
        compare: (a, b) => {
          a = a.months.toLowerCase();
          b = b.months.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => planStore.GymPlanDetail.gym_periods),
};

const canAddNewPlan = computed(() => planStore.subscriptionPlans.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deletePlan(id) {
  confirm({
    title: "Are you sure about this?",
    message: "This plan and all Subscriptions attached to it will be deleted",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await planStore.deletePlan(id, plans.Gym);
        await planStore.fetchGymPlans({ type: plans.Gym });
      }
    },
  });
}

function openModal(action, id = null) {
  if (action == "update" && id != null) {
    selectedGym_plan_period_id.value = id;
  } else {
    selectedGym_plan_period_id.value = null;
  }
  $("#plan-modal").modal("show");
}

function viewMore(gym_plan_id) {
  planStore.viewMore(gym_plan_id, plans.Gym);
  console.log(planStore.GymPlanDetail.gym_periods);
}

onMounted(async () => {
  try {
    await planStore.fetchGymPlans({ type: plans.Gym });
  } catch (error) {
    console.error("Error fetching gym plans:", error);
  }
});
</script>

<style scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-item {
  display: flex;
  align-items: center;
}
.page-btn {
  margin-left: auto;
}
.action-table-data {
  display: flex;
  align-items: center;
}
.edit-delete-action {
  display: flex;
}
</style>