<template>
  <!-- Add Plan -->
  <Modal title="Add-ons" ref="addonsModal" @opened="initData">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="input-blocks">
            <label>Add-On Product</label>
            <input
              v-if="props.userId"
              :value="subscription.user_name"
              disabled
              type="text"
              placeholder="Select a product"
              class="form-control"
            />
            <vue-select
              v-else
              tabindex="1"
              class="w-100"
              id="users"
              :options="products"
              required
              name="user"
              v-model="addons.product_id"
            />
          </div>
        </div>

        <div class="col-lg-12">
          <div class="input-blocks">
            <label>Price</label>
            <input
              type="number"
              class="w-100 form-control"
              required
              name="price"
              v-model="addons.price"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer-btn">
        <button type="button" class="btn btn-cancel me-2" @click="addonsModal.closeModal()">
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-submit"
          id="submit"
          @click="submitForm()"
        >
          Submit
        </button>
      </div>
    </div>
  </Modal>
  <!-- /Add Plan -->
</template>
<script setup>
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useProductStore } from "@/store/productStore";
import Modal from "@/components/modal/modal.vue";
// stores
const productStore = useProductStore();
const addonsModal = ref(null);
const props = defineProps({
  addOnId: null,
});
// data
const addons = reactive({
  product_id: "",
  price: "",
});
const imageUrls = ref([]);

const previewImages = (event) => {
  const files = Array.from(event.target.files);
  // Append new files to existing images

  files.map((file) => {
    imageUrls.value.push(URL.createObjectURL(file));
    products.images.push(file);
    console.log(file);
  });
};

const removeImage = (index) => {
  imageUrls.value.splice(index, 1);
  products.images.splice(index, 1);
};

const combinedImages = computed(() => [...imageUrls.value]);

const getImageUrl = (image) => {
  // Check if image is from the server or locally added
  return typeof image === "object" && image.url ? image.url : image;
};

async function submitForm() {
  if (props.productId) {
    // update
    // alert(props.productId);
    await productStore.updateAddon(props.productId, addons).then(() => addonsModal.value.closeModal());
  } else {
    // add
    await productStore.addAddon(addons).then(() => addonsModal.value.closeModal());
    // await planStore.fetchGymList({type:plans.Gym})
  }
  // $("#plan-modal").modal("hide");
}

const products = computed(() => {
  return productStore.products.map((product) => {
    return { text: product.name, id: product.id };
  });
});
const vendors = computed(() => {
  return productStore.vendors.map((vendor) => {
    return { text: vendor.name, id: vendor.id };
  });
});

watch(
  () => addons,
  () => {
    // setTimeout is used here so the service will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.addOnId,
  (newAddOnId) => {
    imageUrls.value = [];
    if (props.addOnId) {
      const existingAddOns = productStore.AddOns.find(
        (addon) => addon.id == newAddOnId
      );
      if (existingAddOns) {
        Object.assign(addons, existingAddOns);
        // addons.category_id = existingAddOns.category.id;
      }
    } else {
      Object.assign(addons, {
        product_id: "",
        price: ""
      });
    }
  },
  { immediate: true }
);

defineExpose({ openModal: () => addonsModal.value.openModal() });

onMounted(() => {});
</script>

<style scoped>
/* Styling for image previews */
.image-preview {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-preview img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 8px;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(247, 7, 7);
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  font-size: 14px;
}

/* Custom file input styling */
.custom-file {
  position: relative;
  display: inline-block;
}

.custom-file-input {
  display: none; /* Hide the default file input */
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #495057;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Hover and focus effects */
.custom-file-label:hover {
  background-color: #f8f9fa; /* Light gray on hover */
  border-color: #80bdff; /* Blue border on hover */
}

.custom-file-label:focus {
  outline: none; /* Remove outline on focus */
  border-color: #80bdff; /* Blue border on focus */
}
</style>
