<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Sub Plan List</h4>
            <h6>Manage Your Plans</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a href="javascript:void(0);" class="btn btn-added" @click="openModal('new')">
            <vue-feather type="plus-circle" class="me-2"></vue-feather>Add New Plan
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="card-title">Secondary</div>
            </div>
            <div class="card-body">
              <div class="accordion accordion-secondary" id="accordionSecondaryExample">
                <div
                  class="accordion-item"
                  v-for="plan in planStore.subscriptionPlans"
                  :key="plan.id"
                >
                  <h2 class="accordion-header" :id="'headingPrimary' + plan.id">
                    <button
                      style="height: 80px"
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapsePrimary' + plan.id"
                      aria-expanded="false"
                      :aria-controls="'collapsePrimary' + plan.id"
                      @click="viewMore(plan.id)"
                    >
                    <!-- {{ subTypePlan }} -->
                      {{ plan.subscriptionType }}
                    </button>
                  </h2>

                  <div class="row">
                    <div class="col-md-12">
                      <div
                        :id="'collapsePrimary' + plan.id"
                        class="accordion-collapse collapse"
                        :aria-labelledby="'headingPrimary' + plan.id"
                        data-bs-parent="#accordionSecondaryExample"
                      >
                        
                        <filter-table
                          :extra-class="['table', 'datanew']"
                          :columns="SubPlan.columns"
                          :data="SubPlan.data"
                          :filters="SubPlan.filters"
                        >
                          <template #header>
                            <!-- {{ subTypePlan?.plans }} -->
                            <h4>{{ plan.plan_name }}</h4>
                          </template>
                          <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'price'">
                              {{ formatAmount(record.price) }}
                            </template>

                            <template v-else-if="column.key === 'action'">
                              <td class="action-table-data">
                                <div class="edit-delete-action">
                                  <a
                                    @click="
                                      openModal('update', plan.id, record)
                                    "
                                    class="me-2 p-2 mb-0"
                                  >
                                    <i data-feather="edit" class="feather-edit"></i>
                                  </a>
                                  <a
                                    @click="deleteSubPlan(record.id)"
                                    class="me-2 confirm-text p-2 mb-0"
                                    href="javascript:void(0);"
                                  >
                                    <i data-feather="trash-2" class="feather-trash-2"></i>
                                  </a>
                                </div>
                              </td>
                            </template>
                          </template>
                        </filter-table>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SubPlanModal :sub_type_id="selectedSubTypeId" :sub_plan="selectedSubPlan" />
</template>

<script setup>
import SubPlanModal from "./modal/sub-plan-modal.vue";
import { computed, onMounted, ref } from "vue";
import { usePlanStore } from "@/store/planStore";
import { formatAmount } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import { plans } from "@/constants/enum";
// store
const planStore = usePlanStore();

// data
/**
 * Id of Plan that is being edited
 */
const selectedSubTypeId = ref(null);
const selectedSubPlan = ref(null);
const SubPlan = {
  filters: ["name"],
  columns: [
    {
      title: "Name",
      dataIndex: "plan_name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.plan_name.toLowerCase();
          b = b.plan_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      sorter: {
        compare: (a, b) => {
          a = a.price.toLowerCase();
          b = b.price.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Duration",
      key: "period_name",
      dataIndex: "period_name",
      sorter: {
        compare: (a, b) => {
          a = a.months.toLowerCase();
          b = b.months.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => subTypePlan.value?.plans)
};

const subTypePlan = ref(null);
const canAddNewPlan = computed(() => planStore.subscriptionPlans.length < 10);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deleteSubPlan(id) {
  confirm({
    title: "Are you sure about this?",
    message: "This plan and all Subscriptions attached to it will be deleted",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await planStore.deleteSubPlan(id);
        // await planStore.fetchGymPlans({ type: plans.Gym });
      }
      window.location.reload();
    },
  });
}

function viewMore(subscription_type_id) {
  // Update the value of the ref
  subTypePlan.value = planStore.subscriptionPlans.find(
    (subPlan) => subPlan.id == subscription_type_id
  );
}

function openModal(action, subTypeId = null, subPlan = null) {
  if (action == "update" && subTypeId != null && subPlan != null) {
    // console.log(subTypeId);
    selectedSubTypeId.value = subTypeId;
    selectedSubPlan.value = subPlan;
  } else {
    selectedSubTypeId.value = null;
  }
  $("#sub-plan-modal").modal("show");
}

onMounted(async () => {
  // if (SubPlan.data.length == 0) {
  await planStore.fetchSubTypes();
  // }
});
</script>

<style scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-item {
  display: flex;
  align-items: center;
}
.page-btn {
  margin-left: auto;
}
.action-table-data {
  display: flex;
  align-items: center;
}
.edit-delete-action {
  display: flex;
}
</style>
