<template>
  <div class="button-wrapper d-flex justify-content-start mt-3">
    <button
      class="btn mx-2"
      :class="{
        'btn-primary': currentRoute.name === 'subscription-types',
        'btn-outline-primary': currentRoute.name !== 'subscription-types',
      }"
      @click="navigateTo('subscription-types')"
    >
      Subscription Types
    </button>
    <button
      class="btn mx-2"
      :class="{
        'btn-secondary': currentRoute.name === 'period',
        'btn-outline-secondary': currentRoute.name !== 'period',
      }"
      @click="navigateTo('period')"
    >
      Periods
    </button>
    <button
      class="btn mx-2"
      :class="{
        'btn-primary': currentRoute.name === 'plans',
        'btn-outline-primary': currentRoute.name !== 'plans',
      }"
      @click="navigateTo('plans')"
    >
      Plans
    </button>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { computed } from "vue";

const router = useRouter();
const route = useRoute();

const currentRoute = computed(() => route);

function navigateTo(page) {
  router.push({ name: page });
}
</script>

<style scoped>
.button-wrapper {
  margin-bottom: 20px;
}

.btn-primary,
.btn-secondary,
.btn-dark {
  color: #fff;
}

.btn-outline-primary,
.btn-outline-secondary {
  color: #000;
  border: 1px solid;
}

.btn-outline-primary {
  border-color: #007bff;
}

.btn-outline-secondary {
  border-color: #6c757d;
}
</style>
