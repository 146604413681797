<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Subscriptions List</h4>
            <h6>Manage Your Subscriptions</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openOldModal('CREATE_SUBSCRIPTION')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Create old
            Subscriptions
          </a>
        </div>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('CREATE_SUBSCRIPTION')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Create
            Subscriptions
          </a>
        </div>
        <div class="page-btn">
          <router-link
            href="javascript:void(0);"
            class="btn btn-added"
            :to="{name: 'unsynced-subscriptions'}"
            >Unsynced Subscriptions
          </router-link>
        </div>
      </div>
      <!-- USER TABLE -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="Subscriptions.columns"
        :data="Subscriptions.subscribers"
        :filters="Subscriptions.filters"
        >
        <!-- :filters="Subscriptions.filters" -->
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'user'">
            <div class="userimgname gap-2 align-items-center">
              <a href="javascript:void(0);" class="userslist-img bg-img gap-2">
                <Avatar
                  :src="record.profile_picture"
                  :alt="record.user_name"
                  img-class="user-image"
                />
              </a>
              <div>
                <a href="javascript:void(0);">{{ record.user_name }}</a>
              </div>
            </div>
          </template>
          <template v-else-if="column.key === 'account_number'">
            {{ record.account_number}}
          </template>
          <template v-else-if="column.key === 'plan'">
            {{ record.plan }}
          </template>
          <template v-else-if="column.key === 'status'">
            <InputSwitch
              style="width: 150px"
              :value="record.subscription.status"
              :accent-color="getAccentColor(record.subscription.status)"
              :checked="record.subscription.status != subscriptionStatus.inactive"
              :check-disabled="record.subscription.status == subscriptionStatus.expired"
              disabled
              @checkUpdated="updateSubscription($event, record.id)"
            />
          </template>
          <template v-else-if="column.key === 'price'">
            {{ formatAmount(record.subscription.price) }}
          </template>
          <template v-else-if="column.key === 'days_left'">
            {{ formatDate(record.subscription[column.dataIndex]) }}
          </template>
          <template v-else-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <!-- <a
                  @click="openModal('EDIT_SUBSCRIPTION', record.id)"
                  class="me-2 p-2 mb-0"
                >
                  <i data-feather="edit" class="feather-edit"></i>
                </a> -->
                <!-- <a @click="deletePlan(record.id)" class="me-2 confirm-text p-2 mb-0" href="javascript:void(0);">
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a> -->
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>

  <SubscriptionModal :user-id="selectedUserId" ref="subscriptionModal" />
  <OldSubscriptionModal :user-id="selectedUserId" ref="oldSubscriptionModal" />
</template>

<script setup>
import { computed, onMounted, ref, reactive } from "vue";
import { formatAmount, formatDate } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import Avatar from "@/components/avatar.vue";
import { useSubscriptionStore } from "@/store/subscriptionStore";
import SubscriptionModal from "./modals/subscription-modal.vue";
import OldSubscriptionModal from "./modals/old-subscription-modal.vue";
import InputSwitch from "@/components/input-switch.vue";
import { subscriptionStatus } from "@/constants/enum";
import { openModalById } from "@/components/modal/utils";
import { useUserStore } from "@/store/userStore";
import { usePlanStore } from "@/store/planStore";
// store
const subscriptionStore = useSubscriptionStore();
const userStore = useUserStore();
const planStore = usePlanStore();
// data
/**
 * Id of Plan that is being edited
 */
const selectedPlanId = ref(null);
const Subscriptions = ref({
  filters: ["user_name"],
  columns: [
    {
      title: "User",
      dataIndex: "user_name",
      key: "user",
      sorter: true
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
      sorter: true
    },
    {
      title: "Type",
      key: "plan",
      sorter: {
        compare: (a, b) => {
          a = a.plan.toLowerCase();
          b = b.plan.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.subscription.status.toLowerCase();
    //       b = b.subscription.status.toLowerCase();
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "date",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.start_date);
          b = new Date(b.start_date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Expires At",
      dataIndex: "expires_at",
      key: "date",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.expires_at);
          b = new Date(b.expires_at);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Days Left",
      dataIndex: "new_days_left",
      sorter: true,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: true,
    // },
  ],
  subscribers: computed(() => subscriptionStore.subscriptions),
});

const selectedUserId = ref(null);
const subscriptionModal = ref(null);
const oldSubscriptionModal = ref(null);
// ref elements
const collapseHeader = ref(null);
// Methods

function getAccentColor(status) {
  switch (status.toLowerCase()) {
    case "active":
      return "#28c76f";
    case "expired":
      return "var(--PRIMARY-COLOR)";
    default:
      return "#50555a";
  }
}
function openModal(action, id = null) {
  if (action == "CREATE_SUBSCRIPTION") {
    selectedUserId.value = null;
  } else {
    selectedUserId.value = id;
  }
  setTimeout(() => {
    log(subscriptionModal.value.openModal());
  }, 5);
}
function openOldModal(action, id = null) {
  if (action == "CREATE_SUBSCRIPTION") {
    selectedUserId.value = null;
  } else {
    selectedUserId.value = id;
  }
  setTimeout(() => {
    log(oldSubscriptionModal.value.openModal());
  }, 5);
}

async function updateSubscription(status, userId) {
  await subscriptionStore.updateSubscription({
    status: status ? subscriptionStatus.active : subscriptionStatus.inactive,
    user_id: userId,
  });
}

onMounted(async () => {
  if (Subscriptions.value.subscribers.length == 0) {
    await subscriptionStore.fetchSubscribers();
  }
  if (planStore.subscriptionTypes.length == 0) {
    await planStore.fetchSubTypes();
  }


  await userStore.fetchUsers();
});
</script>
