import { createRouter, createWebHistory } from "vue-router";
import SignIn from "@/views/pages/sign-in.vue";

import { isAuth } from "@/utils";
import AdminDashboard from "@/views/pages/dashboard/admin-dashboard.vue";
import UsersList from "@/views/pages/users/users-list.vue";
import AdminList from "@/views/pages/admin/admin-list.vue";
import DevotionPage from "@/views/pages/devotion/devotion-page.vue";
import WordOfDay from "@/views/pages/devotion/word-of-day.vue";
import AddEditDevotion from "@/views/pages/devotion/add-edit-devotion.vue";
import ViewCategories from "@/views/pages/devotion/devotion-categories-page";
import DevotionInfo from "@/views/pages/devotion/devotion-info.vue";
import GymPlanPage from "@/views/pages/plans/gym-plan-page.vue";
import MassagePlanPage from "@/views/pages/plans/massage-plan-page.vue";
import TrainerPlanPage from "@/views/pages/plans/trainer-page.vue";
import GymListPage from "@/views/pages/lists/sub-type-page.vue";
import MassageListPage from "@/views/pages/lists/massage-list-page.vue";
import TrainerListPage from "@/views/pages/lists/trainer-list-page.vue";
import GymPeriodPage from "@/views/pages/period/period-page.vue";
import MassagePeriodPage from "@/views/pages/period/massage-period-page.vue";
import TrainerPeriodPage from "@/views/pages/period/trainer-period-page.vue";
import SubscriptionPage from "@/views/pages/subscriptions/subscription-page.vue";
import unsyncedSubscriptionPage from "@/views/pages/subscriptions/unsynced-subscription-page.vue";
import Error404 from "@/views/pages/error/error-404.vue";
import UpdatePage from "@/views/pages/updates/update-page.vue";

import GymDetailPage from "@/views/pages/plans/gym-plan-detail-page.vue";
import MassageDetailPage from "@/views/pages/plans/massage-plan-detail-page.vue";

import AccordionPage from "@/views/pages/plans/ui-accordion.vue";

import DebitTransactions from "@/views/pages/Transactions/debit-transactions-page.vue";
import CreditTransactions from "@/views/pages/Transactions/credit-transactions-page.vue";

import ProductList from "@/views/pages/products/product-list-page.vue";
import CategoryList from "@/views/pages/category/category-list-page.vue";
import OrderList from "@/views/pages/products/order-list-page.vue";
import DispensedOrderList from "@/views/pages/products/dispensed-order-list-page.vue";
import VendorList from "@/views/pages/vendor/vendor-list-page.vue";
import AddOnsListPage from "@/views/pages/products/addons-list-page.vue";

import SplashScreen from "@/views/pages/splashscreen/splashscreen-list-page.vue";

import VotingCategory from "@/views/pages/Voting/voting-category-list-page.vue";
import NomineeList from "@/views/pages/Voting/voting-nominee-list-page.vue";

import PlanPage from "@/views/pages/plan/plan-page.vue";

import SubPlanPage from "@/views/pages/SubPlans/sub-plan-page.vue";

import Apex from "@/views/pages/uiinterface/charts/apex/chart-apex.vue";
const routes = [
  {
    path: "/accord",
    name: "Accord",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: AccordionPage,
  },
  {
    path: "/",
    name: "sign-in",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: SignIn,
  },
  {
    path: "/dashboard",
    name: "dashboard",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: AdminDashboard,
  },
  {
    path: "/devotional",
    name: "devotion",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "",
        name: "devotion-list",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: DevotionPage,
      },
      {
        path: "view/:encodedData",
        name: "view-devotion",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: DevotionInfo,
      },
      {
        path: ":action/:encodedData",
        name: "add-edit-devotion",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: AddEditDevotion,
      },
      {
        path: "categories",
        name: "view-categories",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: ViewCategories,
      },
      {
        path: "word-of-day",
        name: "word-of-day",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: WordOfDay,
      },
    ],
  },
  {
    path: "/users",
    name: "users",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "",
        name: "users",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UsersList,
      },
    ],
  },
  {
    path: "/admins",
    name: "admins",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "",
        name: "admin",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: AdminList,
      },
    ],
  },
  {
    path: "/subscriptions",
    name: "subscriptions",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: SubscriptionPage,
  },
  {
    path: "/unsynced-subscriptions",
    name: "unsynced-subscriptions",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: unsyncedSubscriptionPage,
  },
  {
    path: "/update",
    name: "updates",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: UpdatePage,
  },
  {
    path: "/:catchAll(.*)",
    component: Error404,
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
  },

  {
    path: "/plan",
    name: "plan",
    redirect: "/gym/plans",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/gym/plans",
        name: "gym-plans",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: GymPlanPage,
      },

      {
        path: "/massage/plans",
        name: "massage-plans",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: MassagePlanPage,
      },
      {
        path: "/trainer/plans",
        name: "personal-trainer",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: TrainerPlanPage,
      },
      {
        path: "/massage/details/:massage_type_id",
        name: "massage-details",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: MassageDetailPage,
      },
    ],
  },

  {
    path: "/list",
    name: "list",
    redirect: "/gym/list",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/gym/list",
        name: "gym-list",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: GymListPage,
      },
      {
        path: "/massage/list",
        name: "massage-list",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: MassageListPage,
      },
      {
        path: "/trainer/list",
        name: "trainer-list",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: TrainerListPage,
      },
    ],
  },
  {
    path: "/sub-plans",
    name: "SubPlans",
    redirect: "/sub-plans",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/sub-plans",
        name: "subscription-plans",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: SubPlanPage,
      },
      {
        path: "/sub/types",
        name: "subscription-types",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: GymPlanPage,
      },
      {
        path: "/subscription/types",
        name: "subscription-types",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: GymListPage,
      },
      {
        path: "/period",
        name: "period",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: GymPeriodPage,
      },
      {
        path: "/plans",
        name: "plans",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: PlanPage,
      },
    ],
  },

  // {
  //   path: "/periods",
  //   name: "period",
  //   redirect: "/period",

  //   meta: {
  //     requiresAuth: true,
  //     permissionsRequired: [],
  //   },
  //   children: [

  //     {
  //       path: "/massage/period",
  //       name: "massage-period",

  //       meta: {
  //         requiresAuth: true,
  //         permissionsRequired: [],
  //       },
  //       component: MassagePeriodPage,
  //     },
  //     {
  //       path: "/trainer/period",
  //       name: "trainer-period",

  //       meta: {
  //         requiresAuth: true,
  //         permissionsRequired: [],
  //       },
  //       component: TrainerPeriodPage,
  //     },
  //   ],
  // },

  {
    path: "/transactions",
    name: "transaction",
    redirect: "/transactions/credits",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/transactions/credits",
        name: "CreditTransactions",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: CreditTransactions,
      },
      {
        path: "/transactions/debits",
        name: "DebitTransactions",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: DebitTransactions,
      },
    ],
  },

  {
    path: "/product_categories",
    name: "categories",
    redirect: "/category/list",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/category/list",
        name: "CategoryList",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: CategoryList,
      },
      // {
      //   path: "/transactions/debits",
      //   name: "DebitTransactions",
      //   meta: {
      //     requiresAuth: true,
      //     permissionsRequired: [],
      //   },
      //   component: DebitTransactions,
      // },
    ],
  },

  {
    path: "/vendors",
    name: "vendors",
    redirect: "/vendors/list",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/vendors/list",
        name: "VendorList",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: VendorList,
      },
      // {
      //   path: "/transactions/debits",
      //   name: "DebitTransactions",
      //   meta: {
      //     requiresAuth: true,
      //     permissionsRequired: [],
      //   },
      //   component: DebitTransactions,
      // },
    ],
  },
  {
    path: "/orders",
    name: "orders",
    redirect: "/orders/list",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/orders/list",
        name: "OrderList",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: OrderList,
      },
      {
        path: "/orders/dispensed/list",
        name: "DispensedOrderList",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: DispensedOrderList,
      },
      // {
      //   path: "/transactions/debits",
      //   name: "DebitTransactions",
      //   meta: {
      //     requiresAuth: true,
      //     permissionsRequired: [],
      //   },
      //   component: DebitTransactions,
      // },
    ],
  },
  {
    path: "/voting-categories",
    name: "Voting",
    redirect: "/voting-categories",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/voting-categories",
        name: "VotingCategory",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: VotingCategory,
      },
      {
        path: "/voting-nominees",
        name: "Nominees",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: NomineeList,
      },
      // {
      //   path: "/transactions/debits",
      //   name: "DebitTransactions",
      //   meta: {
      //     requiresAuth: true,
      //     permissionsRequired: [],
      //   },
      //   component: DebitTransactions,
      // },
    ],
  },

  {
    path: "/products",
    name: "products",
    redirect: "/products/list",
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "/products/list",
        name: "ProductList",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: ProductList,
      },
      {
        path: "/addons",
        name: "AddOns",
        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: AddOnsListPage,
      },
    ],
  },

  {
    path: "/splash-screen",
    name: "splashscreen",
    component: SplashScreen,
    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
  },

  {
    path: "/apex",
    name: "apex",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Apex,
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

/**
 *
 * @param {import("vue-router").RouteLocationNormalized} route
 */
const middleware = (route) => {
  let result = { toLogin: false, canContinue: true };
  if (route.meta.requiresAuth) {
    if (isAuth()) {
      result.canContinue = true;
    } else {
      result.toLogin = true;
      result.canContinue = false;
    }
  }
  return result;
};

router.beforeEach((to, from, next) => {
  const { toLogin, canContinue } = middleware(to);
  if (canContinue) {
    next();
  } else if (toLogin) {
    next("?next=" + to.name);
  }
  window.scrollTo({ top: 0, behavior: "smooth" });
});
