<template>
  <!-- Add user -->
  <div class="modal fade" id="user-quick-view-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4 style="margin-left: 17px;">{{ userStore.singleUserQuickView.user_name }} ({{userStore.singleUserQuickView.account_number?.account_number ?? userStore.singleUserQuickView.account_number}})</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <filter-table
                :extra-class="['table', 'datanew']"
                :columns="userTransaction.columns"
                :data="userTransaction.users"
              >
                <template #header>
                  <div class="d-flex justify-content-between w-100">
                    <h4 style="margin-bottom: 15px;">Registration Date: {{ userStore.singleUserQuickView.account_created_at }}</h4>

                    <h4 style="margin-bottom: 15px;">Balance: ₦{{ userStore.singleUserQuickView.balance }}</h4>

                  </div>
                    <!-- <h4 style="float:right">{{props.selected_account_number}}</h4>   -->
                </template>
                {{ user }}
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'name'">
                    <div class="userimgname gap-2 align-items-center">
                      <div>
                        <a href="javascript:void(0);">{{ record.name }}</a>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'account_number'">
                    <a
                      href="javascript:void(0);"
                      @click="openHistoryModal(record.account_number)"
                      style="color: red;"
                      >{{ record.account_number }}</a
                    >
                  </template>
                </template>
              </filter-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { useUserStore } from "@/store/userStore";
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
// stores
const userStore = useUserStore();

const userTransaction = ref({
  filters: ["name", "email"],
  columns: ref([
    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",
      sorter: {
        compare: (a, b) => {
          a = a.admin_name.toLowerCase();
          b = b.admin_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Plan",
      dataIndex: "plan",
      sorter: {
        compare: (a, b) => {
          a = a.admin_name.toLowerCase();
          b = b.admin_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Started At",
      dataIndex: "started_at",
      key: "started_at",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.started_at);
          b = new Date(b.started_at);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Expires At",
      dataIndex: "expires_at",
      key: "expires_at",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.expires_at);
          b = new Date(b.expires_at);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ]),
  users: computed(() => userStore.singleUserQuickView.subscriptions),
  selectedUserId: ref(null),
});

const props = defineProps({
  selected_user_id: null,
  selected_account_number: null,
  selected_balance: null,
  selected_name: null,
  action: null,
  
});
// data
const user = ref({
  account_number: null,
  userName: null,
});

watch(
  () => user,
  () => {
    // setTimeout is used here so the user will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.selected_user_id,
  () => {
    if (props.selected_user_id) {
      user.value = userStore.users.find(
        (user) => user.id == props.selected_user_id
      );
    } else {
      user.value = {};
    }
  },
  { immediate: true }
);

onMounted(async () => {
  // await userStore.fetchUserTransactionHistory(props.account_number);
});
</script>

<style scoped>
.custom-modal-two {
  max-width: 80%; /* Set this to a desired percentage or fixed width */
  width: 80%; /* Optional: ensure it takes the full width of max-width */
}

@media (max-width: 768px) {
  .custom-modal-two {
      max-width: 90%; /* Adjust for smaller screens if needed */
  }
}

</style>
