<template>
  <!-- Add plan -->
  <div class="modal fade" id="plan-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Plans</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        required
                        v-model="plan.name"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Description</label>
                      <input
                        type="text"
                        name="duration"
                        required
                        v-model="plan.description"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                  <div>
                    <label for="feature-input">Features:</label>
                    <div class="input-group mb-3">
                      <input
                        id="feature-input"
                        type="text"
                        v-model="featuresInput"
                        class="form-control"
                        placeholder="Enter a feature"
                      />
                      <button
                        type="button"
                        @click="addFeature"
                        class="btn btn-primary"
                      >
                        Add
                      </button>
                    </div>

                    <div class="features-preview">
                      <h4>Features Preview:</h4>
                      <ul class="feature-list">
                        <li
                          v-for="(feature, index) in plan.features"
                          :key="index"
                        >
                          <span>{{ feature }}</span>
                          <button
                            type="button"
                            @click="removeFeature(index)"
                            class="btn btn-danger btn-sm"
                          >
                            Remove
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Add-Ons</label>
                      <vue-select
                        tabindex="1"
                        class="w-100"
                        id="add-ons"
                        :options="addOns"
                        label="name"
                        @select="addToList"
                        :reduce="(product) => product.id"
                        v-model="addon"
                      />
                    </div>

                    <div
                      class="added-addons mt-3"
                      v-if="selectedAddOns"
                    >
                      <h5>Selected Add-Ons</h5>
                      <table class="table table-bordered table-sm">
                        <thead class="thead-light">
                          <tr>
                            <th>#</th>
                            <th>Add-On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(addOn, index) in selectedAddOns"
                            :key="addOn.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ addOn.product }}</td>
                            <td>
                              <button
                                class="btn btn-outline-danger btn-sm"
                                @click="removeAddOn(index)"
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { usePlanStore } from "@/store/planStore";
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { plans } from "@/constants/enum";
import { useProductStore } from "@/store/productStore";
// stores
const planStore = usePlanStore();

const addOnStore = useProductStore();
const selectedAddOns = ref([]);
const addon = ref(null);

const props = defineProps({
  planId: null,
});
// data
const plan = reactive({
  name: "",
  description: "",
  features: [],
  add_on_ids: [],
});

const featuresInput = ref("");

async function submitForm() {
  if (props.planId) {
    
    await planStore.updatePlan(props.planId, plan);
  } else {
    // add
    await planStore.addPlan(plan);
  }
  $("#plan-modal").modal("hide");
}

watch(
  () => plan,
  () => {
    // setTimeout is used here so the plan will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.planId,
  (newPlanId) => {
    if (newPlanId) {
      const existingPlan = planStore.Plans.find((plan) => plan.id == newPlanId);
      console.log(existingPlan);
      
      if (existingPlan) {
        plan.name = existingPlan.name;
        plan.description = existingPlan.description;
        plan.features = existingPlan.features;
        plan.add_on_ids = existingPlan.add_on_ids;
        selectedAddOns.value = [];
        console.log(plan.add_on_ids);
        plan.add_on_ids.forEach((addOnId) => {
          const addOn = addOnStore.AddOns.find((item) => item.id == addOnId);
          console.log(addOn);
          
          if (addOn) {
            selectedAddOns.value.push(addOn);
          }
        });
      }
    } else {
      plan.name = "";
      plan.description = "";
      plan.features = [];
      plan.add_on_ids = [];
      selectedAddOns.value = [];
    }
  },
  { immediate: true }
);

const addFeature = () => {
  if (featuresInput.value.trim() !== "") {
    // Add the trimmed feature to the array
    plan.features.push(featuresInput.value.trim());

    // Clear the input field after adding
    featuresInput.value = "";
  }
};

const removeFeature = (index) => {
  if (index >= 0 && index < plan.features.length) {
    // Remove the feature at the specified index
    plan.features.splice(index, 1);

    // Update the input field to reflect changes
    featuresInput.value = plan.features.join("; ");
  }
};

const addOns = computed(() => {
  return addOnStore.AddOns.map((addOn) => {
    return { text: addOn.product, id: addOn.id };
  });
});

function addToList(selectedAddOn) {
  // Find the product by ID
  const addOn = addOnStore.AddOns.find((item) => item.id == selectedAddOn.id);

  // Add to the selectedAddOns list if not already added
  if (addOn && !selectedAddOns.value.some((item) => item.id === addOn.id)) {
    selectedAddOns.value.push(addOn);
    plan.add_on_ids.push(addOn.id);
    addon.value = "";
  }
  addon.value = "";
}

function removeAddOn(index) {
  selectedAddOns.value.splice(index, 1);
  plan.add_on_ids.splice(index, 1);
}

onMounted(() => {});
</script>
<style scoped>
.features-preview {
  margin-bottom: 20px; /* Add space below the features section */
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; /* Space between feature items */
}

.feature-list li span {
  margin-right: 10px;
}

.feature-list button {
  margin-left: 10px;
}

.add-ons-section {
  margin-top: 20px; /* Add space above Add-Ons section */
}
</style>
