<template>
  <!-- Add Plan -->
  <div class="modal fade" id="sub-plan-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <!-- {{ subPlan }} -->
                <h4>
                  {{ props.sub_plan ? "Update " : "Add " }} Subscription Plan
                </h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Subscription Type</label>
                      <!-- <input
                        type="text"
                        name="name"
                        required
                        v-model="plan.name"
                        :class="{ 'form-control': true }"
                      /> -->
                      <vue-select
                        :options="subTypes"
                        placeholder="Choose"
                        v-model="subPlan.subscription_type_id"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Duration</label>
                      <vue-select
                        :options="durations"
                        placeholder="Choose"
                        v-model="subPlan.period_id"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Plan</label>
                      <vue-select
                        :options="plans"
                        placeholder="Choose"
                        v-model="subPlan.plan_id"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Price</label>
                      <input
                        type="number"
                        name="price"
                        required
                        v-model="subPlan.price"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { usePlanStore } from "@/store/planStore";
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
// import { plans } from "@/constants/enum";
// stores
const planStore = usePlanStore();

const props = defineProps({
  sub_type_id: null,
  sub_plan: null,
});
// data
const subPlan = ref({
  subscription_type_id: null,
  period_id: null,
  plan_id: null,
  price: null,
});

async function submitForm() {
  if (props.sub_type_id) {
    // update
    await planStore.updateSubPlan(props.sub_plan.id, subPlan.value);
  } else {
    // add
    await planStore.addSubPlan(subPlan.value);
  }
  // $("#sub-plan-modal").modal("hide");
  window.location.reload();
}

watch(
  () => subPlan,
  () => {
    // setTimeout is used here so the plan will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.sub_type_id,
  () => {
    if (props.sub_type_id) {

      // Find the subscription type object by its ID
      const obj = planStore.subscriptionTypes.find(
        (type) => type.id == props.sub_type_id
      );

      if (obj) {
        // Find the specific plan within the subscription type
        const subscriptionPlan = obj.plans.find(
          (plan) => plan.plan_id == props.sub_plan.plan_id
        );

        if (subscriptionPlan) {
          // Update a reactive variable or perform an action with the plan
          subPlan.value = {
            subscription_type_id: props.sub_type_id,
            period_id: subscriptionPlan.period_id,
            plan_id: subscriptionPlan.plan_id,
            price: subscriptionPlan.price,
          };
        } else {
          console.error("Plan not found for the given sub_plan.plan_id");
        }
      } else {
        console.error("Subscription type not found for the given sub_type_id");
      }
    } else {
      // Clear the subPlan value if sub_type_id is null
      subPlan.value = {
        subscription_type_id: null,
        period_id: null,
        plan_id: null,
        price: null,
      };
    }
  },
  { immediate: true }
);

const plans = computed(() => {
  return planStore.Plans.map((plan) => {
    return { text: plan.name, id: plan.id };
  });
});
const durations = computed(() => {
  return planStore.Periods.map((duration) => {
    return { text: duration.name, id: duration.id };
  });
});

const subTypes = computed(() => {
  return planStore.subscriptionTypes.map((type) => {
    return { text: type.subscriptionType, id: type.id };
  });
});
onMounted(async () => {
  if (planStore.Periods.length == 0) {
    planStore.fetchPeriods();
  }
  if (planStore.Plans.length == 0) {
    planStore.fetchPlans();
  }
});
</script>
