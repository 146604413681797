<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Add Ons</h4>
            <!-- <h6>Manage Your Products</h6> -->
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a href="javascript:void(0);" class="btn btn-added" @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add To List
          </a>
        </div>
      </div>
      <!-- USER TABLE -->
      <!-- {{ AddOnList.data }} + -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="AddOnList.columns"
        :data="AddOnList.data"
        :filters="AddOnList.filters"
      >
        <template #header>
          <h4>Available Add Ons</h4>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'category'">{{ record.category.name }}</template>
          <template v-if="column.key === 'available_quantity'">
            <a href="#" @click="openRestockHistory(record.id)">{{
              record.available_quantity
            }}</a>
          </template>
          <template v-else-if="column.key === 'status'">
            <a-switch
              :checked="record.is_active"
              @change="updateProductStatus($event, record.id)"
            />
          </template>
          <template v-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <a @click="openModal('update', record.id)" class="me-2 p-2 mb-0">
                  <i data-feather="edit" class="feather-edit"></i>
                </a>
                <a
                  @click="deleteAddon(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
                <!-- <a
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                  @click="restockModal(record.id, record.available)"
                  ><i
                    data-feather="plus-circle"
                    class="feather-plus-circle"
                    alt="Ind"
                  ></i>
                </a> -->
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <AddOnsModal :addon-id="selectedAddOnId" ref="addOnsModal"/>
</template>

<script setup>
import GymNavigationButtons from "@/views/layouts/gym-setting.vue";
import RestockModal from "./modal/restock-modal.vue";
import RestockHistoryModal from "./modal/restock-history-modal.vue";
import AddOnsModal from "./modal/addons-list-modal.vue";
import { computed, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { useProductStore } from "@/store/productStore";
import { formatAmount } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import { plans } from "@/constants/enum";
import { useRouter } from "vue-router";
// store
const productStore = useProductStore();
// data
/**
 * Id of Plan that is being edited
 */
const selectedAddOnId = ref(null);
const addOnsModal = ref(null);
const selectedProductQuantity = ref(null);
const AddOnList = ref({
  filters: ["product_name"],
  columns: [
    {
      title: "Product Name",
      dataIndex: "product",
      sorter: {
        compare: (a, b) => {
          a = a.product.toLowerCase();
          b = b.product.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: {
        compare: (a, b) => {
          a = a.price.toLowerCase();
          b = b.price.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    
    // {
    //   title: "Status",
    //   key: "status",
    //   sorter: true,
    // },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => productStore.AddOns),
});

const canAddNewPlan = computed(() => productStore.products.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deleteAddon(id) {
  confirm({
    title: "Are you sure about this?",
    message: "Some Subscriptions might still be using it",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await productStore.deleteAddon(id);
      }
    },
  });
}

function openModal(action, id = null) {
  if (action == "update") {
    selectedAddOnId.value = id;
  } else {
    selectedAddOnId.value = null;
  }
  
  if(productStore.products.length == 0){
    productStore.fetchProducts();
  }
  addOnsModal.value.openModal();
}

async function updateProductStatus(isActive, userId) {
  await productStore.updateProductStatus(userId, {
    status: isActive ? "active" : "inactive",
  });
  await productStore.fetchProducts();
}

onMounted(async () => {
  if (AddOnList.value.data.length == 0) {
    await productStore.fetchAddOns();
  }
});
</script>
